import {MafiInformation, SnackbarUtil, RoTrailMafiService, RoTrailStackService, MafiLocationCoordinates } from "@blg/blg-core";
import {StackUpdateDto} from "../models/api/dto/StackUpdateDto";
import i18next from "i18next";
import {setShowLoading} from "../store/slices/global";
import {store} from "../store/store";
import {showApiError} from "./errorHelper";

/**
 * updates the mafi information
 * @param payload
 * @param isStack
 */
export const updateMafi = async (payload: MafiInformation, isStack: boolean): Promise<boolean> => {
    try {
        payload.minimalData ? await RoTrailMafiService.instance.mergeMafi(payload!) : await RoTrailMafiService.instance.updateMafi(payload!);
        if (payload.minimalData) {
            SnackbarUtil.success(!isStack ? i18next.t('UNION.UPDATE.SUCCESS', {value: payload.mafiNo})
                : i18next.t('UNION.UPDATE.STACK_SUCCESS'));
        } else {
            SnackbarUtil.success(!isStack ? i18next.t('UNION.UPDATE.SAVE_SUCCESS', {value: payload.mafiNo})
                : i18next.t('UNION.UPDATE.STACK_SUCCESS'));
        }

        return true;
    } catch (e) {
        if (e.response.data && e.response.data.message) {
            SnackbarUtil.error(e.response.data.message);
        }
        SnackbarUtil.error(!isStack ? i18next.t('UNION.UPDATE.ERROR') : i18next.t('UNION.UPDATE.STACK_ERROR'));
        return false;
    }
}

/**
 * creates a new mafi with the mafi information
 * @param payload
 */
export const createMafi = async (payload: MafiInformation): Promise<boolean> => {
    try {
        await RoTrailMafiService.instance.mergeMafi(payload);
        SnackbarUtil.success(i18next.t('UNION.CREATE.SUCCESS', {'value': payload!.mafiNo}));
        return true;
    } catch (e) {
        showApiError(e);
        return false;
    }
}

export const saveMafiStack = async (mafis: MafiInformation[]): Promise<boolean> => {
    try {
        store.dispatch(setShowLoading(true));
        const sortedStackList = [...mafis].reverse();
        const stackPayload: StackUpdateDto[] = sortedStackList.map((mafi, index) => {
            return {
                mafiNo: mafi.mafiNo,
                stackRowNo: index + 1
            }
        });

        const stackResponse = await RoTrailStackService.instance.buildStack(stackPayload);
        const stackMafis = MafiInformation.parseFromArray(stackResponse.data) as MafiInformation[];
        SnackbarUtil.success(i18next.t('UNION.CREATE_STACK_MESSAGE.SUCCESS', {'stackNo': stackMafis[0].stackNo}));
        return true;
    } catch (e) {
        showApiError(e);
        return false;
    } finally {
        store.dispatch(setShowLoading(false));
    }
}

export const saveMafi = async (information: MafiInformation, notation: string, location: MafiLocationCoordinates,
                               isNewMafi: boolean, isStack: boolean):
    Promise<{  result: boolean, apiMafi?: MafiInformation  }> => {
    if (!information || !location) return { result: false, apiMafi: undefined };

    const mafiInfo = MafiInformation.parseFromObject({
        ...information,
        mafiLocation: {
            location: notation,
            geoLocLongitude: location.long,
            geoLocLatitude: location.lat,
            locationInfo: '',
            dangerous: false,
            hall: false,
            roof: false,
            stock: false,
        }
    });

    try {
        // creates / updates the mafi, if this fails, return early here
        store.dispatch(setShowLoading(true));
        const result = isNewMafi ? await createMafi(mafiInfo)
            : await updateMafi(mafiInfo, isStack);
        return { result, apiMafi: mafiInfo }
    } catch (e) {
        showApiError(e);
        return { result: false, apiMafi: undefined };
    } finally {
        store.dispatch(setShowLoading(false));
    }
};
