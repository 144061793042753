import {MafiInformation, MafiStackOrderComponent, RoTrailMafiService, RoTrailStackService} from "@blg/blg-core";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import WetDialogComponent from "../../../components/dialog/wetDialog.component";
import {showApiError} from "../../../misc/errorHelper";
import {useAppSelector} from "../../../store/hooks";
import {setLastSavedLocation} from "../../../store/slices/custom";
import {store} from "../../../store/store";
import {setActiveTutorial, setActiveView} from "../../../store/slices/global";
import MafiInformationComponent from "../../../components/mafi/mafiInformation.component";
import LoadingStatusComponent from "../../../components/mafi/loadingStatus.component";
import {setShowLoading} from "../../../store/slices/global";
import {SnackbarUtil, LiveMafiLocationComponent, MafiNotation, MafiLocationCoordinates} from "@blg/blg-core";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import EmptyMafiPlaceholder from "../../../components/mafi/emptyMafiPlaceholder.component";
import {
    defaultButtonProps,
    defaultButtonStyle,
    secondaryButtonProps,
    secondaryButtonStyle
} from "../../../styles/defaultStyles";
import {MafiLocationDto} from "../../../models/api/dto/MafiLocationDto";
import {MafiLoadStatusDto} from "../../../models/api/dto/MafiLoadStatusDto";

const SetPlaceDetailsView: React.FC = () => {
    const {mafiNr} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const lastSavedLocation = useAppSelector(state => state.custom).lastSavedLocation;

    const mdeLatitude = useAppSelector(state => state.custom).latitude;
    const mdeLongitude = useAppSelector(state => state.custom).longitude;

    const [activeMafi, setActiveMafi] = useState<MafiInformation | null>(null);
    const [stackMafis, setStackMafis] = useState<MafiInformation[]>([]);
    const [isEmpty, setEmpty] = useState<boolean>(false);
    const [location, setLocation] = useState<MafiLocationCoordinates | null>(null);
    const [notation, setNotation] = useState<MafiNotation | null>(null);
    const [showWetDialog, setShowWetDialog] = useState(false);

    const [locationValid, setLocationValid] = useState(false);

    useEffect(() => {
        store.dispatch(setActiveTutorial({tutorial: "LOCATION_DETAILS", show: true}));
        loadMafiData();
    }, []);

    /**
     * is called when the user goes back to the inventory overview
     */
    function onCancelPressed() {
        navigate((-1));
    }

    function notationChanged(isValid: boolean, notation?: MafiNotation) {
        if (isValid) {
            setNotation(notation!);
            store.dispatch(setLastSavedLocation(notation!.notation));
        }
        setLocationValid(isValid);
    }

    const loadMafiData = async (): Promise<void> => {
        store.dispatch(setShowLoading(true));
        try {
            // searches for the mafi based on the number and gets the details of the mafi based on that
            const response = await RoTrailMafiService.instance.getByMafiNo(mafiNr!);
            if (response.data) {
                const mafi = MafiInformation.parseFromObject(response.data);

                if (mafi.stackNo) {
                    const response = await RoTrailStackService.instance.getStackByMafiNo(mafiNr!);
                    const apiItems = MafiInformation.parseFromArray(response.data) as MafiInformation[];
                    apiItems.sort((a, b) => b.stackRowNo - a.stackRowNo);
                    setStackMafis(apiItems);
                }

                setActiveMafi(mafi);
            } else {
                // the mafis found for the selected mafi nr, navigates back to the search view and shows
                // toast, also adds a empty placeholder on this page (in case the user somehow stays on the page)
                SnackbarUtil.error(t('MAFI_INFORMATION.EMPTY', {'value': mafiNr}));
                navigate(-1);
                setEmpty(true);
            }
        } catch (e) {
            // on error returns to the search view and shows an error toast
            if (e.response && e.response.status !== 401) {
                SnackbarUtil.error(t('MAFI_INFORMATION.ERROR', {'value': mafiNr}));
                navigate(-1);
            }
        } finally {
            // removes the loading state
            store.dispatch(setShowLoading(false));
        }
    }

    /**
     * resets the input field to scan the next mafi
     */
    const onSavePressed = async (confirmed: boolean) => {
        if (!activeMafi || !location) return;

        // Show the confirm dialog for wet sensitive data
        if (!confirmed && activeMafi._hasWetSensitiveData && !notation!.wetSensitive) {
            setShowWetDialog(true);
            return;
        }

        store.dispatch(setShowLoading(true));
        try {
            await updateMafiLocation();
            await updateMafiLoadingStatus();

            activeMafi.stackNo ? SnackbarUtil.success(t('SET_PLACE.SUCCESS_STACK')) : SnackbarUtil.success(t('SET_PLACE.SUCCESS'));
            navigate(-1);
        } catch (e) {
            if (e.response && e.response.status !== 401) {
                if (e.response.data && e.response.data.response_status) {
                    SnackbarUtil.error(e.response.data.response_status.failure_text);
                } else {
                    SnackbarUtil.error(t('SET_PLACE.ERROR'));
                }
            }
        } finally {
            store.dispatch(setShowLoading(false));
        }
    }

    /**
     * updates the location of the mafi
     */
    const updateMafiLocation = async () => {
        let updateMafiNo = activeMafi!.mafiNo;

        if (stackMafis && stackMafis.length > 0) {
            updateMafiNo = stackMafis[stackMafis.length - 1].mafiNo;
        }

        const payload: MafiLocationDto = {
            mafiNo: updateMafiNo,
            location: notation!.notation,
            lat: location!.lat,
            long: location!.long,
        };

        try {
            await RoTrailMafiService.instance.setMafiLocation(payload);
        } catch (e) {
            showApiError(e);
        }
    }

    /**
     * updates the loading status of the the mafi
     */
    const updateMafiLoadingStatus = async () => {
        // if the status is not available, not set, just return
        if (activeMafi?.loadStatus === undefined || activeMafi?.loadStatus === null) return;

        // creates dto with mafiNo and current set loadingStatus
        const loadStatusDto: MafiLoadStatusDto = {
            mafiNo: activeMafi.mafiNo,
            loadStatus: activeMafi.loadStatus
        };

        try {
            await RoTrailMafiService.instance.setMafiLoadStatus(loadStatusDto);
        } catch (e) {
            showApiError(e);
        }
    }

    const onLoadingStatusChanged = (value: 'LEER' | 'VOLL') => {
        setActiveMafi(MafiInformation.parseFromObject({
            ...activeMafi,
            loadStatus: value,
        }));
    }

    /**
     * resetting the app bar title when the user enters the task selection view
     */
    useEffect(() => {
        store.dispatch(setActiveView({text: t('TASK_SELECTION.SET_PLACE'), params: null}));
    }, []);

    return (
        <Box sx={{
            display: "flex", flexDirection: "column", minHeight: '100%', p: 1,
            height: "100%", overflowY: "auto"
        }}>
            {isEmpty && <EmptyMafiPlaceholder mafiNr={mafiNr!}/>}

            {activeMafi && <Box>
                {/* location component to set the location of the mafi */}
                <Box sx={{backgroundColor: theme.palette.white.main, borderRadius: "6px", p: 1}}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                    }}>{t("SET_PLACE.LOCATION_SYSTEM")} </Typography>
                    <Typography variant={"body2"}>{activeMafi!.mafiLocation.location}</Typography>

                    <Box sx={{mt: 1}}>
                        <LiveMafiLocationComponent
                            dangerousGood={activeMafi._hasDangerousData}
                            latitude={mdeLatitude}
                            longitude={mdeLongitude}
                            lastSavedLocation={lastSavedLocation}
                            onLocationChangedCallback={(l) => setLocation(l)}
                            onNotationChangedCallback={(v, n) => notationChanged(v, n)}
                        />
                    </Box>
                </Box>
                <Box sx={{mt: 0.5}}>
                    <LoadingStatusComponent
                        information={activeMafi!}
                        isParentUpdating={true}
                        onStatusChanged={onLoadingStatusChanged}
                    />
                </Box>
                {stackMafis.length > 0 &&
                    <Box sx={{mt: 0.5}}>
                        <MafiStackOrderComponent
                            stackNo={activeMafi!.stackNo}
                            isDisabled={true}
                            deletable={false}
                            items={stackMafis}
                            slim/>
                    </Box>
                }
                <Box sx={{mt: 1}}>
                    <MafiInformationComponent information={activeMafi!}/>
                </Box>
                <Box>
                    {/* save button to save the place */}
                    <Button
                        onClick={() => onSavePressed(false)}
                        disabled={!activeMafi || !locationValid}
                        {...defaultButtonProps}
                        sx={{...defaultButtonStyle, mt: 3}}>
                        {activeMafi.stackNo ? t('GENERAL.SAVE_STACK') : t('GENERAL.SAVE')}
                    </Button>

                    {/* cancel button to cancel place setting */}
                    <Button
                        {...secondaryButtonProps}
                        sx={{...secondaryButtonStyle, mt: 2}}
                        onClick={onCancelPressed}>
                        {t('GENERAL.CANCEL')}
                    </Button>
                </Box>
            </Box>}
            <WetDialogComponent showDialog={showWetDialog} closeDialog={async (confirmed: boolean) => {
                setShowWetDialog(false);
                if (confirmed) {
                    await onSavePressed(confirmed);
                }

            }}></WetDialogComponent>
        </Box>
    );
};

export default SetPlaceDetailsView;
