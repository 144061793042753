import { SnackbarUtil } from "@blg/blg-core";
import i18n from '../i18n';

export const showApiError = (e: any, customError?: string) => {
    if (e.response && e.response.data && e.response.data.failure_text) {
        SnackbarUtil.error(e.response.data.failure_text);
    } else if (e.response && e.response.data && e.response.data.message && e.response.data.message !== "Bad Request") {
        SnackbarUtil.error(e.response.data.message);
    } else {
        if (e.message) {
            SnackbarUtil.error(e.message);
        } else if (customError) {
            SnackbarUtil.error(customError);
        } else {
            SnackbarUtil.error(i18n.t('ERROR.UNKNOWN_ERROR'));
        }
    }
}
